import React from "react"
import Banner from "../components/Banner/banner"
import TextBlock from "../components/TextBlock/textBlock"
import Button from "../components/Button/button"
import Contact from "../components/Contact/contact"
import { Link } from "react-scroll"
import { StaticImage } from 'gatsby-plugin-image'

import { useStaticQuery, graphql } from "gatsby"
import { IconContext } from "react-icons"
import { MdDone, MdClear } from "react-icons/md"

const HomePage = () => {
  const data = useStaticQuery(graphql`{
  file(relativePath: {eq: "styvsto-Ausstellung-Plakat_v2_1000.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 90, layout: FULL_WIDTH)
    }
  }
}
`)
  return (
    <>
      <Banner />
      <TextBlock
        id="about"
        title="Glimm"
        paragraph="Glimm ist ein zauberhaftes Wesen, das in einem verwunschenen Wald lebt. Mit seinem besten Freund, einem pinken Ballon, holt er die Farben in seinen Wald."
      >
        <StaticImage src="../images/glimm_fotobuch.jpg" alt="Glimm und die Farben, ein Kunstkinderbuch" />
        <p>Glimm und die Farben ist ein Kunst-Kinderbuch, welches unter der amgegebemem Adresse oder auch telefonisch bestellt werden kann. Die Bilder aus dem Buch sind auch als Postkarten erhältlich.</p>
      </TextBlock>
      <TextBlock
        id="kleid"
        title="Glimm im Kleid, Einsiedeln"
      >
        <StaticImage src="../images/glimm_im_kleid.jpg" alt="Glimm im Laden Kleid, Einsiedeln" />
        <p>Glimm hat sich aus seinem Wald gewagt und wohnt vorübergehend im Kleid (Benzigerstrasse 4, 8840 Einsiedeln). Wer Glimm bewundern möchte und das Buch kaufen will (39.-) - nichts wie hin....</p>
      </TextBlock>
      <TextBlock
        id="events"
        title="Events"
        paragraph=""
      >
        <StaticImage src="../images/styvsto-Ausstellung-Plakat_v2_1000.png" alt="Ausstellung im Museum Fram Einsiedeln" />
        <p>
          Das Ehepaar <bold>Styv & Sto</bold> aka Yvonne und Dejan Stojanovic zeigen Werke aus ihrem
künstlerischen Schaffen der letzten Jahre.</p>
<p>
Das in Einsiedeln ansässige Paar verarbeitet seit Jahren facettenreich und künstlerisch den
ganz normalen Wahnsinn namens Alltag. Nun haben sie beschlossen, ihr Schaffen gemeinsam
einem grösseren Publikum zu zeigen und freuen sich, Sie vom 11.-13. Juni 2021 im Museum
Fram in Einsiedeln zu begrüssen.</p>
<p>
Styv präsentiert die Bilder zu ihrem Buch «Glimm und die Farben» sowie einige Einzelwerke.</p>
<p>Glimm ist ein zauberhaftes Wesen, das in einem verwunschenen Wald lebt. Gemeinsam mit
seinem kleinen Freund, dem pinken Ballon, holt er die Farben in seinen Wald.</p>
<p><a href="https://sto-artist.com" target="_blank">Sto</a> zeigt unter anderem seine Photokunstwerke zum Thema «my new #fckcorona hobbies».
Die Photomontagen sprühen vor Witz und erlauben einen ganz neuen und erheiternden Blick
auf unseren Alltag in den vergangenen 12 Monaten.
        </p>
      </TextBlock>
      <Contact
        id="contact"
        title="Kontakt"
        subtitle="Yvonne Stojanovic aka styv | 0797077795 | y.stolu@yahoo.in"
      >
      </Contact>

    </>
  )
}

export default HomePage
